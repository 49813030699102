<template>
  <component
    :is="semantic ? `div` : `h${level}`"
    ref="headingRef"
    class="heading transform-gpu overflow-hidden !leading-tight"
    :class="isMounted ? 'opacity-100' : 'opacity-0'"
  >
    <span ref="textRef">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

interface HeadingProps {
  /**
   * Switches between a generic div or a semantic heading tag.
   */
  semantic?: boolean
  /**
   * Sets the heading styles and when used with a semantic tag it generates the H tag.
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6
  isAnimated?: boolean
  delay?: number
  animate?: boolean
  isHeroHeading?: boolean
}

const props = withDefaults(defineProps<HeadingProps>(), {
  semantic: false,
  level: 1,
  isAnimated: true,
  delay: 0,
  animate: true,
  isHeroHeading: false
})
const headingRef = ref<HTMLElement | null>(null)
const textRef = ref<HTMLElement | null>(null)
const split = ref<null | any>(null)
const tween = ref<null | any>(null)
const isMounted = ref(false)

const { stop } = useIntersectionObserver(
  headingRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting && props.animate) {
      tween.value?.play()
    }
  },
  {
    threshold: 0.1
  }
)

watch(
  () => props.animate,
  (_, oldValue) => {
    if (!oldValue) {
      tween.value?.play()
    }
  }
)

const emit = defineEmits<{(event: 'completed'): void }>()

onMounted(async () => {
  gsap.registerPlugin(SplitText)
  isMounted.value = true

  await nextTick(() => {
    if (textRef.value && props.isAnimated) {
      split.value = new SplitText(textRef.value, { type: 'chars, words' })

      gsap.set(split.value.chars, { perspective: 900 })

      tween.value = gsap.from(split.value.chars, {
        delay: props.delay,
        opacity: 1,
        rotationY: -90,
        rotationX: 0,
        translateZ: 100,
        paused: true,
        stagger: {
          each: 0.03
        },
        ease: 'power2.out',
        onComplete: () => {
          emit('completed')
        }
      })
    }

    if (props.isHeroHeading) {
      tween.value?.play()
    }
  })
})

onUnmounted(() => {
  tween?.value?.kill()
  stop()
})
</script>
